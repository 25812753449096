<template>
  <div class="relative" :class="{ [custom]: !!custom }">
    <label v-if="!!label || $slots.label" :for="uid" class="text-13 mb-4 block text-gray-700 dark:text-gray-100">
      <slot name="label">
        {{ label }}
      </slot>
    </label>

    <field
      :id="uid"
      v-model="model"
      class="rounded-4 text-13 border border-gray-200 bg-white px-8 py-10 text-black outline-none placeholder:text-gray-300 focus-within:border-blue-500 dark:border-gray-600 dark:bg-black dark:text-white placeholder:dark:text-gray-500"
      :class="{
        '!border-red-500': !!hasErrors,
        '!border-gray-200 !bg-gray-50 !text-gray-300 dark:!border-gray-600 dark:!bg-gray-800 dark:!text-gray-500':
          disabled,
        '!border-[--bb-textarea__border] !bg-[--bb-textarea__bg] !text-[--bb-textarea__text] dark:!border-[--bb-textarea__border--dark] dark:!bg-[--bb-textarea__bg--dark] dark:!text-[--bb-textarea__text--dark]':
          custom,
        'w-full': !cols,
      }"
      :disabled="disabled"
      :name="name"
      :placeholder="placeholder"
      :rows="rows"
      :cols="cols"
      :readonly="readonly"
      :rules="rules"
      as="textarea"
      :autocomplete="autocomplete"
    />
    <error-message class="text-11 absolute left-0 top-full text-red-500" :name="name" />
  </div>
</template>

<script lang="ts" setup>
import { Field, ErrorMessage, useField } from 'vee-validate';
import { useId } from 'vue';
import { type StringSchema } from 'yup';

const {
  name,
  rules = '',
  placeholder = '',
  label = '',
  autocomplete = 'off',
  rows = 2,

  custom = '',
} = defineProps<{
  // Using HTMLInputElement['autocomplete'] results in 'Expression produces a union type that is too complex to represent'
  autocomplete?: string;
  disabled?: boolean;
  label?: string;
  name: string;
  placeholder?: string;
  readonly?: boolean;
  rows?: number;
  cols?: number;
  rules?: Record<string, unknown> | StringSchema | string;

  custom?: string;
}>();

const model = defineModel<number | string>();

const uid = useId();

const hasErrors = useField(name).errorMessage;
</script>
